import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "HeadingType", "inheritedStyles", "theme", "contentfulLivePreview"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Heading.style';
var Heading = function Heading(_ref) {
  var children = _ref.children,
    className = _ref.className,
    HeadingType = _ref.HeadingType,
    inheritedStyles = _ref.inheritedStyles,
    theme = _ref.theme,
    contentfulLivePreview = _ref.contentfulLivePreview,
    others = _objectWithoutProperties(_ref, _excluded);
  var headingLevel = HeadingType.substr(1, 1);
  return __jsx(HeadingType, _extends({
    className: "nva-heading ".concat(className),
    role: "heading",
    "aria-level": headingLevel
  }, contentfulLivePreview, others), children);
};
Heading.defaultProps = {
  inheritedStyles: '',
  HeadingType: 'h1'
};
export default styled(Heading).withConfig({
  componentId: "sc-1lhs05s-0"
})(["", ";"], styles);
export { Heading as HeadingVanilla };